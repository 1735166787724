import React, {useState, useEffect} from 'react';

import { Stack, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TablePagination } from '@material-ui/core';
import { BodyText, Modal, PrimaryButton, SecondaryButton, TextInput } from "@hm-group/fabric-components/brand/hm";
import { CloseIcon, PlusIcon } from "@hm-group/fabric-icons";

import instance from '../../../services/UserService';
import PageContainer from '../../../components/PageContainer';
import UserList from './UserList';
import UserFields from './UserFields';

export default function ShowroomUsers({ selectedShowroom, submenu }) {
  const loggedInUserIsAdmin = parseInt(localStorage.getItem('user_role')) === 2;

  const [pageTitle, setPageTitle] = useState();
  
  // User list
  const [filterData, setFilterData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [allShowroomsList, setAllShowroomsList] = useState();
  const [users, setUsers] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderByCreationDate, setOrderByCreationDate] = useState('DESC');

  // Delete user
  const [infoForUserDeletion, setInfoForUserDeletion] = useState();

  // Add & Edit user
  const [showUserScreen, setShowUserScreen] = useState();
  const [existingUserInfo, setExistingUserInfo] = useState({});
  const [userSuccessMessage, setUserSuccessMessage] = useState();

  useEffect(() => {
    const loadShowrooms = async() => {
      const response = await instance.get('/getShowrooms', {params: {
        'sort': 'showroomName,asc',
        'size': 100,
      }});
      setAllShowroomsList(response.data.content.map(showroom => ({ value:showroom.showroomId, label:showroom.showroomName})));
    };
    loadShowrooms();
  },[]); // empty [] replaces OnMount

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (showUserScreen) {
      setPageTitle(`${capitalizeFirstLetter(showUserScreen)} user`);
    } else {
      setPageTitle(`Showroom users ${!loggedInUserIsAdmin ? '- '+selectedShowroom.name : '' }`);
    }
  },[selectedShowroom, showUserScreen]);

  // Get users whenever the props change
  useEffect(() => {
    if (selectedShowroom && selectedShowroom.id) {
      const getUsers = () => {
        instance
        .get('/user/getAllUsers', {
          params: {
            'role': 1, // Show only regular users, hide those with ADMIN role
            'userShowrooms.showroomId': !loggedInUserIsAdmin ? parseInt(selectedShowroom.id) : (filterData.filterShowroom ? parseInt(filterData.filterShowroom) : null),
            'firstName': `contains(${filterData.firstName})`,
            'lastName': `contains(${filterData.lastName})`,
            'userName': `contains(${filterData.email})`,
            'size': rowsPerPage,
            'page': page,
            'sort': 'createdDate,'+orderByCreationDate,
          }
        })
        .then(response => {
          setUsers(response.data);
        })  
        .catch(error => {
          console.log(error);
        })
      }

      getUsers();
    }
},[filterData, page, rowsPerPage, orderByCreationDate, selectedShowroom, userSuccessMessage])

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Creation date
  const changeOrderByCreationDate = () => {
    setOrderByCreationDate(orderByCreationDate === 'DESC' ? 'ASC' : 'DESC');
  }

  // Delete user
  const startUserDeletion = (userID, userFullName) => {
    setInfoForUserDeletion(
      {
        userID: userID,
        userFullName: userFullName,
      }
    )
  }

  const deleteUser = () => {
    // API for deleting the user
    // do we need to display a confirmation message here? Something like "User successfully deleted"?
    setInfoForUserDeletion();
    // reload the user list - maybe this can be done by addind some prop to the useEffect that loads the users
  }

  // Add & Edit user
  const startUserEdit = (existingUser) => {
    const defaultShowroom = existingUser.userShowrooms.reduce((def, showroom) => {
      if (showroom.defaultShowroom === 1) {
        def = showroom.showroomId;
      }
      return def;
    },'')

    const otherShowrooms = existingUser.userShowrooms.reduce((oth, showroom) => {
      if (showroom.defaultShowroom === 0) {
        oth.push(showroom.showroomId);
      }
      return oth;
    },[])

    setExistingUserInfo({
      id: existingUser.userId,
      firstName: existingUser.firstName,
      lastName: existingUser.lastName,
      alias: existingUser.userUniqueName,
      email: existingUser.userName,
      country: existingUser.country,
      defaultShowroom: defaultShowroom,
      otherShowrooms: otherShowrooms,
    })

    setShowUserScreen('edit');
  }

  const hideUserScreen = () => {
    setShowUserScreen();
    setExistingUserInfo({});
  };

   const displayUserSuccessMessage = (successMessage) => {
    hideUserScreen();
    setUserSuccessMessage(successMessage);
   }

  return (
    <PageContainer title={pageTitle} submenu={submenu}>
      {!showUserScreen && infoForUserDeletion && (
        <Modal
          isOpen={!!infoForUserDeletion}
          heading="Delete user"
          content={
            <BodyText dangerouslySetInnerHTML={{__html: `Are you sure that you want to delete ${infoForUserDeletion.userFullName}?`}}/>
          }
          closeLabel="Close"
          size="medium"
          onClose={() => setInfoForUserDeletion()}
          actions={
            <>
              <PrimaryButton onClick={() => deleteUser()}>Delete</PrimaryButton>
              <SecondaryButton onClick={() => setInfoForUserDeletion()}>Cancel</SecondaryButton>
            </>
          }
        />
      )}

      {!showUserScreen && userSuccessMessage && (
        <Modal
          isOpen={!!userSuccessMessage}
          heading="Success!"
          content={<BodyText dangerouslySetInnerHTML={{__html: `${userSuccessMessage}`}}/>}
          closeLabel="Close"
          size="medium"
          onClose={() => setUserSuccessMessage()}
        />
      )}

      {!showUserScreen && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="end" spacing={2} paddingBottom={4}>
            <Stack direction="row" spacing={2} alignItems="end">
              <TextInput
                label="First name"
                name="firstName"
                isMarginless
                onChange={e => {
                  setFilterData({...filterData, firstName: e.target.value});
                  setPage(0);
                }}
                value={filterData.firstName}
              />
    
              <TextInput
                label="Last name"
                name="lastName"
                isMarginless
                onChange={e => {
                  setFilterData({...filterData, lastName: e.target.value});
                  setPage(0);
                }}
                value={filterData.lastName}
              />
    
              <TextInput
                label="Email"
                name="email"
                isMarginless
                onChange={e => {
                  setFilterData({...filterData, email: e.target.value});
                  setPage(0);
                }}
                value={filterData.email}
              />
    
              {loggedInUserIsAdmin && allShowroomsList && (
                <FormControl sx={{ minWidth: 220 }} >
                  <InputLabel id="filterShowroom-label">Showroom</InputLabel>
                  <Select
                    id="filterShowroom"
                    label="Showroom"
                    value={filterData.filterShowroom ? filterData.filterShowroom : ''}
                    onChange={e => {
                      setFilterData({...filterData, filterShowroom: e.target.value});
                      setPage(0);
                    }}
                  >
                    {allShowroomsList.map(showroom => <MenuItem value={showroom.value}>{showroom.label}</MenuItem>)}
                  </Select>
                </FormControl>
              )}

              <SecondaryButton
                onClick={() => setFilterData({
                  firstName: '',
                  lastName: '',
                  email: '',
                })}
                type="button"
                iconElement={<CloseIcon size='small' />}
              >
                Clear filters
              </SecondaryButton>
            </Stack>
    
            {loggedInUserIsAdmin && (
              <PrimaryButton
                onClick={() => setShowUserScreen('add')}
                type="button"
                iconElement={<PlusIcon size='small' />}
              >
                Add user 
              </PrimaryButton>	
            )}
          </Stack>    
    
          <UserList
            loggedInUserIsAdmin={loggedInUserIsAdmin}
            users={users.content}
            startUserDeletion={startUserDeletion}
            startUserEdit={startUserEdit}
            changeOrderByCreationDate={changeOrderByCreationDate}
            allShowroomsList={allShowroomsList}
          />
    
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={(users && users.totalElements) ? users.totalElements : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* Should this be a new page? */}
      {showUserScreen && (
        <UserFields
          screenType={showUserScreen}
          hideUserScreen={hideUserScreen}
          allShowroomsList={allShowroomsList}
          existingUserInfo={existingUserInfo}
          displayUserSuccessMessage={displayUserSuccessMessage}
        />
      )}
    </PageContainer>
  );
}

