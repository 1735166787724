import React, { useState, useEffect } from 'react';

import { Stack, Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { PrimaryButton, SecondaryButton, TextInput } from "@hm-group/fabric-components/brand/hm";

import instance from '../../../services/UserService';
import OptionalShowrooms from './OptionalShowrooms';

export default function UserFields({
  screenType,
  allShowroomsList,
  existingUserInfo,
  hideUserScreen,
  displayUserSuccessMessage
}) {
  const [userInfo, setUserInfo] = useState(existingUserInfo ? existingUserInfo : {
    firstName: '',
    lastName: '',
    alias: '',
    email: '',
    country: '',
  });
  const [confirmationButtonDisabled, setConfirmationButtonDisabled] = useState();

  const updateOptionalShowroomsUserInfo = (optionalShowrooms) => {
    setUserInfo({...userInfo,  otherShowrooms: optionalShowrooms})
    // should we call them other or optional showrooms?
  }

  const confirmationButtonText = screenType === 'add' ? 'Add user' : 'Save changes';

   // Disable confirmation button button until form has been filled out
   useEffect(() => {
    if (!userInfo.firstName || !userInfo.lastName || !userInfo.alias || !userInfo.email || !userInfo.country || !userInfo.defaultShowroom) {
      setConfirmationButtonDisabled(true);
    } else {
      setConfirmationButtonDisabled(false);
    }
  },[userInfo])

  const confirmationButtonFunction = () => {
    const regularUserRoleID = 1;

    const userShowrooms = userInfo.otherShowrooms.filter(showroomID => !!showroomID).map(showroomID => (
      {
        'showroomId': parseInt(showroomID),
        'userRoleId': regularUserRoleID,
        'defaultShowroom': 0,
      }
    ));

    const defaultUserShowroom = {
      'showroomId': parseInt(userInfo.defaultShowroom),
      'userRoleId': regularUserRoleID,
      'defaultShowroom': 1,
    }

    userShowrooms.push(defaultUserShowroom);

    const userData = {
      'userId': existingUserInfo ? existingUserInfo.id : null,
      'role': regularUserRoleID,
      'firstName': userInfo.firstName,
      'lastName': userInfo.lastName,
      'userUniqueName': userInfo.alias,
      'userName': userInfo.email,
      'country': userInfo.country,
      'userShowrooms': userShowrooms,
    }

    if ( screenType === 'add' ) {
      instance
      .post('/user/insertUser', userData)		
      .then(response => {
        displayUserSuccessMessage(`User ${userData.firstName} ${userData.lastName} was successfully added.`);
      })
      .catch(error => {
        // should we show an error here?
        console.log(error);
      })

    } else {
      instance
      .put('/user/updateUser', userData)		
      .then(response => {
        displayUserSuccessMessage(`User ${userData.firstName} ${userData.lastName} was successfully updated.`);
      })
      .catch(error => {
        // should we show an error here?
        console.log(error);
      })
    }
  }

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={6}>
        <Grid item xs={12} md={5} lg={4}>
          <Stack direction="column" spacing={2}>
            <TextInput
              isRequired
              label="First name"
              name="firstName"
              onChange={e => setUserInfo({...userInfo,  firstName: e.target.value})}
              value={userInfo.firstName}
            />

            <TextInput
              isRequired
              label="Last name"
              name="lastName"
              onChange={e => setUserInfo({...userInfo,  lastName: e.target.value})}
              value={userInfo.lastName}
            />

            <TextInput
              isRequired
              label="Alias"
              name="alias"
              onChange={e => setUserInfo({...userInfo,  alias: e.target.value})}
              value={userInfo.alias}
              isDisabled={screenType === 'edit'}
            />

            <TextInput
              isRequired
              label="Email"
              name="email"
              onChange={e => setUserInfo({...userInfo,  email: e.target.value})}
              value={userInfo.email}
            />

            <TextInput
              isRequired
              label="Country"
              name="country"
              onChange={e => setUserInfo({...userInfo,  country: e.target.value})}
              value={userInfo.country}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Stack direction="column" spacing={2}>
            <FormControl required>
              <InputLabel id="defaultShowroom-label">Default showroom</InputLabel>
              <Select
                id="defaultShowroom"
                label="Default showroom"
                value={userInfo.defaultShowroom ? userInfo.defaultShowroom : ''}
                onChange={e => setUserInfo({...userInfo,  defaultShowroom: e.target.value})}
              >
                {allShowroomsList.map(showroom => <MenuItem value={showroom.value}>{showroom.label}</MenuItem>)}
              </Select>
            </FormControl>

            <OptionalShowrooms
              allShowroomsList={allShowroomsList}
              existingShowrooms={existingUserInfo.otherShowrooms}
              updateUserInfo={updateOptionalShowroomsUserInfo}
              defaultShowroomChosen={!!userInfo.defaultShowroom}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} style={{ paddingTop: '30px' }}>
        <PrimaryButton
          onClick={() => confirmationButtonFunction()}
          type="button"
          isDisabled={confirmationButtonDisabled}
        >
          {confirmationButtonText}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => hideUserScreen()}
          type="button"
        >
          Cancel
        </SecondaryButton>
      </Stack>
    </>
  );
}
