import React, {useState, useEffect } from 'react';

import { Stack, Grid } from "@mui/material";

import { SecondaryButton, TextInput, NumberInput, Select, DropDownItem, BannerNotification } from "@hm-group/fabric-components/brand/hm";
import { ArrowRightIcon } from "@hm-group/fabric-icons";

import instance from '../../services/UserService';
import TextareaWithLimitedLength from './TextareaWithLimitedLength';

const AddSampleFieldsWithPN = ({ selectedShowroomID, sampleData, setSampleData, productInfoLoaded, setProductInfoLoaded, maxTextLengths }) => {
 
  const [seasonList, setSeasonList] = useState([]);
  const [loadError, setLoadError] = useState();
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState(false);

  useEffect( () => {
    const loadSeasons = async() => {
      const response = await instance.get("/getSeasons");
      setSeasonList(response.data.map(season => season.seasonName));
    };

    loadSeasons();
  },[])

  const loadProductInfo = () => {
    setIsLoadingProductInfo(true);

    instance.get("/getProductDetails/"+sampleData.productNumber+"/"+selectedShowroomID+"/"+sampleData.season)
    .then(response => {
      setSampleData({
        ...sampleData,
        sectionName: response.data.sectionName,
        departmentName: response.data.departmentName,
        price: response.data.salePrice,
        currencyCode: response.data.currencyCode,
        sizes: response.data.sizes,
        inShopWeek: response.data.inShopWeek,
        productClassification: response.data.productClassificationDesc,
        composition: response.data.composition,
        channelOnline: response.data.channelName1,
        channelOffline: response.data.channelName0,
        colors: response.data.colourGroupName,
      })

      if(loadError) {
        setLoadError();
      }

      setProductInfoLoaded(true);
      setIsLoadingProductInfo(false);
    })
    .catch(error => {
      // resetting this value to force re-render of BannerNotification component
      setLoadError(); 
      setIsLoadingProductInfo(false);
      setLoadError('Product loading failed. Please make sure that you have filled out the correct Product number and Season.');
    });
  }

  return (
    <Grid container columnSpacing={{xs: 0, md: 3}} rowSpacing={{xs: 2, lg: 0}}>
      <Grid item xs={12} lg={6}>
        <Stack direction="column" spacing={2}>
          <NumberInput
            label="Product number"
            name="productNumber"
            value={sampleData.productNumber}
            onChange={e => setSampleData({...sampleData, productNumber: e.target.value})}
            helperText={sampleData.productNumber.length && sampleData.productNumber.length !== 7 ? `Product number must be 7 characters long. It is currently ${sampleData.productNumber.length} characters long.` : null}
            placeholder="Enter 7 digits product number"
            isRequired
            isDisabled={productInfoLoaded}
            isInvalid={sampleData.productNumber.length && sampleData.productNumber.length !== 7}
          />
          
          {!productInfoLoaded && (
            <>
              <Select
                label="Season"
                ariaLabel="Season"
                value={sampleData.season}
                name="season"
                placeholder="Select season"
                onSelected={e => setSampleData({...sampleData, season: e.label})}
                isRequired
              >
                {seasonList.map(season => <DropDownItem label={season} />)}
              </Select>

              <SecondaryButton
                onClick={() => loadProductInfo()}
                isDisabled={!(sampleData.productNumber && sampleData.productNumber.length === 7 && sampleData.season)}
                isLoading={isLoadingProductInfo}
                type="button"
                iconPosition="end"
                iconElement={<ArrowRightIcon size='small' />}
              >
                Load product info
              </SecondaryButton>
              
              {loadError && (
                <BannerNotification 
                  severity="error"
                  message={loadError}
                  closeLabel="Close"
                />
              )}
            </>
          )}

          {productInfoLoaded && (
            <>
              <TextInput
                isDisabled
                label="Section"
                name="sectionName"
                value={sampleData.sectionName}
              />

              <TextInput
                isDisabled
                label="Department"
                name="departmentName"
                value={sampleData.departmentName}
              />

              <TextInput
                isDisabled
                label="Price"
                name="price"
                value={`${sampleData.price} ${sampleData.currencyCode}`}
              />
            </>
          )}
        </Stack>
      </Grid>

      {productInfoLoaded && (
        <Grid item xs={12} lg={6}>
          <Stack direction="column" spacing={2}>
            <TextInput
              label="Sizes"
              name="sizes"
              value={sampleData.sizes}
              onChange={e => setSampleData({...sampleData, sizes: e.target.value})}
            />

            <TextInput
              label="In Shop"
              name="inShopWeek"
              value={sampleData.inShopWeek}
              onChange={e => setSampleData({...sampleData, inShopWeek: e.target.value})}
            />

            <TextareaWithLimitedLength
              value={sampleData.composition}
              name='composition'
              label='Composition'
              maxLength={maxTextLengths.composition}
              updateState={(e) => setSampleData({...sampleData, composition: e.target.value})}
            />

            <TextareaWithLimitedLength
              value={sampleData.information}
              name='information'
              label='Sample information'
              maxLength={maxTextLengths.information}
              updateState={(e) => setSampleData({...sampleData, information: e.target.value})}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}

export default AddSampleFieldsWithPN;