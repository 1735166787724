import React, {useState, useEffect} from 'react';

import { Stack, Autocomplete, TextField } from '@mui/material';
import { TablePagination } from '@material-ui/core';
import { ToggleSwitch, NumberInput } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';
import LoansList from './LoansList';
import PageContainer from '../../components/PageContainer';

export default function SearchLoans({ selectedShowroomID, submenu }) {
  const [contacts, setContacts] = useState([]);
  const [loanList, setLoanList] = useState({totalElements: 0});
  const [shouldIncludeReturnedLoans, setShouldIncludeReturnedLoans] = useState(false);
  const [loanNumber, setLoanNumber] = useState();
  const [productNumber, setProductNumber] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [orderByCreationDate, setOrderByCreationDate] = useState('DESC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Contacts
  useEffect( () => {
      const loadContacts = async() => {
        const fullContactsInfo = await instance.get("/getContacts/"+selectedShowroomID);
        const basicContactsInfo = fullContactsInfo.data.map(singleContact => ({ value:singleContact.contactId, label: (singleContact.firstName + " " + singleContact.lastName)}));
  
        setContacts(basicContactsInfo);
      };
      loadContacts();
  },[selectedShowroomID])

  // Get loans whenever the props change
  useEffect(() => {
    const getLoans = () => {
      instance
      .get('/loan/getAllLoans', {
        params: {
          'showroomId': parseInt(selectedShowroomID),
          'emptyLoan': false,
          'loanId': loanNumber,
          'contactId': selectedContact ? selectedContact.value : null,
          'samples.productNumber': productNumber,
          'size': rowsPerPage,
          'page': page,
          'sort': 'createdDate,'+orderByCreationDate,
          'lent': shouldIncludeReturnedLoans ? null : 'ne(0)',
        }
      })
      .then(response => {
        setLoanList(response.data);  
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getLoans();
  },[shouldIncludeReturnedLoans, loanNumber, productNumber, selectedContact, orderByCreationDate, page, rowsPerPage, selectedShowroomID])

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Creation date
  const changeOrderByCreationDate = () => {
    setOrderByCreationDate(orderByCreationDate === 'DESC' ? 'ASC' : 'DESC');
  }

  return (
    <PageContainer title="Search loans" submenu={submenu}>
      <Stack direction="row" spacing={2} paddingBottom={4}>
        <Autocomplete
          disablePortal
          classes={{
            option: 'hm-select-options-text'
          }}
          disabled={contacts.length === 0 ? true : false}
          id="contacts-standard"
          value={selectedContact ? selectedContact.label : null}
          options={contacts}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className="hm-text-field hm-text-field--single margin-top-8"
              placeholder="Search for contact"
            />
          )}
          onChange={(event, value) => {
            setSelectedContact(value);
            setPage(0);
          }}
        />

        <NumberInput
          isLabelVisible={false}
          name="loanNumber"
          value={loanNumber || ''}
          onChange={e => {
            setLoanNumber(e.target.value);
            setPage(0);
          }}
          placeholder="Loan number"
        />

        <NumberInput
          isLabelVisible={false}
          name="productNumber"
          value={productNumber || ''}
          onChange={e => {
            setProductNumber(e.target.value);
            setPage(0);
          }}
          placeholder="Product number"
        />
      </Stack>

      <ToggleSwitch
        id="includeReturnedLoansToggle"
        onChange={() => {
          setShouldIncludeReturnedLoans(!shouldIncludeReturnedLoans)
          setPage(0);
        }}
        label="Include returned loans"
      />

      <LoansList loans={loanList.content} changeOrderByCreationDate={changeOrderByCreationDate}/>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={loanList.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PageContainer>
  );
}