import {React,useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import { makeStyles, CssBaseline, createTheme, ThemeProvider, AppBar, Toolbar } from '@material-ui/core';
import { Container, Stack, Select, MenuItem } from '@mui/material';
import { BodyText, InlineLink, SecondaryLink, NavigationLarge } from "@hm-group/fabric-components/brand/hm";
import { PlusIcon, ViewListIcon, NewWindowIcon, SettingsIcon, SearchIcon, ReturnsIcon, ErrorIcon, AccountDefaultIcon } from "@hm-group/fabric-icons";

import instance from './services/UserService';

import { ErrorPage } from './pages/ErrorPage';
import AccessRestrictedPage from './pages/AccessRestrictedPage';
import SearchLoans from './pages/Loans/SearchLoans';
import OverdueLoans from './pages/Loans/OverdueLoans';
import AddLoan from './pages/Loans/AddLoan';
import ReturnSample from './pages/Loans/ReturnSample';
import LoanDetails from "./pages/Loans/LoanDetails";
import PrintLabel from './pages/Samples/PrintLabel';
import Contacts from './pages/Contacts/Contacts';
import AddContact from './pages/Contacts/AddContact';
import ContactInfo from './pages/Contacts/ContactInfo';
import Medias from './pages/Medias/Medias';
import AddMedia from './pages/Medias/AddMedia';
import MediaInfo from './pages/Medias/MediaInfo';
import ShowroomUsers from './pages/Admin/Users/ShowroomUsers';
import Showrooms from './pages/Admin/Showrooms/Showrooms';
import EditShowroom from './pages/Admin/Showrooms/EditShowroom';

const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
    background: {
      default: "#FFF"
    },
    font: {
      default: "HM Sans Regular"
    }
  },
  overrides:{
    MuiAppBar:{
      root:{
        transform:'translateZ(0)'
      }  
    },
    MuiGrid:{
      container:{
        flexWrap:'nowrap'
      }
    }
  },
  props:{
    MuiIconButton:{
      disableRipple:true
    },
   }
})

const useStyles = makeStyles({
  appMain: {
    width: '100%'
  }
})

export default function App2() {
  const classes = useStyles();
  const [showroomList, setShowroomList] = useState([]);
  const [assignedShowroom, setAssignedShowroom] = useState();
  const [selectedShowroomLabel, setSelectedShowroomLabel] = useState('');
  const [market, setMarket] = useState('');
  const [loanMenuSelected, setLoanMenuSelected] = useState(false);
  const [contactMenuSelected, setContactMenuSelected] = useState(false);
  const [sampleMenuSelected, setSampleMenuSelected] = useState(false);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const loadShowrooms = async() => {
      const response = await instance.get("/getAllShowrooms/"+userId);
      setMarket(response.data[0].country_code);

      if(localStorage.getItem('selectedShowroom')) {
        setAssignedShowroom(localStorage.getItem('selectedShowroom'));
      } else {
        setAssignedShowroom(response.data.find(showroom => showroom.default_showroom === 1)?.showroom_id);
      }

      var resultShowrooms = response.data.map(eachShowroom => ({ value:eachShowroom.showroom_id, label:eachShowroom.showroom_name}));
      setShowroomList(resultShowrooms);
    };

    loadShowrooms();
  },[]);

  useEffect(() => {
    setSelectedShowroomLabel(showroomList.find(showroom => showroom.value === parseInt(assignedShowroom))?.label);
  },[assignedShowroom, showroomList]);

  localStorage.setItem('market', market);

  useEffect(() => {
     const url = window.location.pathname;
      if(url === '/contacts'){
        setContactMenuSelected(true);
        setLoanMenuSelected(false);
        setSampleMenuSelected(false);
      } else if(url === '/loans') {
        setLoanMenuSelected(true);
        setContactMenuSelected(false);
        setSampleMenuSelected(false);
      } else if(url === '/print-label') {
        setSampleMenuSelected(true);
        setContactMenuSelected(false);
        setLoanMenuSelected(false);
      }
	}, []);

  const contactsSubmenu = [
    {
      url: '/contacts',
      label: 'Contacts',
      icon: <ViewListIcon size="small" />,
    },
    {
      url: '/medias',
      label: 'Medias',
      icon: <ViewListIcon size="small" />,
    },
    {
      url: '/contacts/add',
      label: 'Add Contact',
      icon: <PlusIcon size="small" />,
    },
    {
      url: '/medias/add',
      label: 'Add Media',
      icon: <PlusIcon size="small" />,
    }
  ]

  const loansSubmenu = [
    {
      url: '/loans',
      label: 'Search loans',
      icon: <SearchIcon size="small" />,
    },
    {
      url: '/overdue-loans',
      label: 'Overdue loans',
      icon: <ErrorIcon size="small" />,
    },
    {
      url: '/add-loan',
      label: 'Add Loan',
      icon: <PlusIcon size="small" />,
    },
    {
      url: '/return-sample',
      label: 'Return sample',
      icon: <ReturnsIcon size="small" />,
    }
  ]

  const loggedInUserIsAdmin = parseInt(localStorage.getItem('user_role')) === 2;

  const adminSubmenu = [
    {
      url: '/admin/users',
      label: 'Showroom users',
      icon: <AccountDefaultIcon size="small" />,
    },
    ...(loggedInUserIsAdmin ? [
      {
        url: '/admin/showrooms',
        label: 'Showrooms',
        icon: <ViewListIcon size="small" />,
      }
    ] : [])
  ]

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.appMain}>
          <Router>
              <AppBar position="static" color="transparent">
                <Toolbar disableGutters>
                  <Container maxWidth="xl">
                    <Stack direction='row' spacing={{ xs: 2, sm: 3, md: 5 }} justifyContent='space-between' alignItems='center'>
                      <div>
                        <img src= {require('./images/HMGroupLoGo.jpg')} alt='hmgrouplogo' style={{display: 'block'}}/>
                      </div>
                              
                      <Stack alignItems='center' direction='row' spacing={{ xs: 2, sm: 3, md: 5, lg: 8 }}>
                        <NavigationLarge 
                          href="/loans"
                          // inline={loanMenuSelected}
                          // selected={loanMenuSelected}
                          // onClick={(event)=>{
                          //     setLoanMenuSelected(true);
                          //     setContactMenuSelected(false);
                          //     setSampleMenuSelected(false);
                          // }}
                        >
                          Loans
                        </NavigationLarge>
                        
                        <NavigationLarge 
                          href="/print-label"
                          // inline={sampleMenuSelected}
                          // selected={sampleMenuSelected}
                          // onClick={(event)=>{
                          //     setContactMenuSelected(false);
                          //     setLoanMenuSelected(false);
                          //     setSampleMenuSelected(true);
                          // }}
                        >
                          Samples
                        </NavigationLarge>
                        
                        <NavigationLarge 
                          href="/contacts"
                          // inline={contactMenuSelected}
                          // selected={contactMenuSelected}
                          // onClick={(event)=>{
                          //   setSampleMenuSelected(false);
                          //   setLoanMenuSelected(false);
                          //   setContactMenuSelected(true);
                          // }}
                        >
                          Contacts
                        </NavigationLarge>
                        
                        <SecondaryLink
                          iconPosition='end'
                          iconElement={<NewWindowIcon size='small'/>}
                          href="https://productsearchtool.hmgroup.com/"
                          target="_blank"
                        >
                          Search product
                        </SecondaryLink>
                      </Stack>

                      <Stack alignItems='center' direction='row' spacing={{ xs: 1, sm: 1, md: 2 }}>
                        {assignedShowroom && (
                          <Select
                            id="showroom-select"
                            value={assignedShowroom}
                            onChange={e => {
                              setAssignedShowroom(e.target.value);
                              localStorage.setItem('selectedShowroom', e.target.value);
                            }}
                          >
                            {showroomList.map(showroom => <MenuItem key={showroom.value} value={showroom.value} name={showroom.label}>{showroom.label}</MenuItem>)}
                          </Select>
                        )}

                        <NavigationLarge href="/admin/users">
                          <SettingsIcon />
                        </NavigationLarge>
                      </Stack>
                    </Stack>
                  </Container>
              </Toolbar> 
              </AppBar>
              <div className="content-container">
              {assignedShowroom && (
                <Routes>
                  <Route path="/" element={ <Navigate to='/loans' /> } />
                  {/* Temporary fix, remove this redirect later */}
                  <Route path="/loan/default/1" element={ <Navigate to='/loans' /> } />
                  <Route path = '/contacts' element={<Contacts selectedShowroomID={assignedShowroom} submenu={contactsSubmenu} />}/>
                  <Route path = '/contacts/add' element={<AddContact selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
                  <Route path = '/contact/:contactID' element={<ContactInfo selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
                  <Route path = '/medias' element={<Medias selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
                  <Route path = '/medias/add' element={<AddMedia selectedShowroomID={assignedShowroom} submenu={contactsSubmenu} />}/>
                  <Route path = '/media/:mediaID' element={<MediaInfo selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
                  <Route path = '/loans' element={<SearchLoans selectedShowroomID={assignedShowroom} submenu={loansSubmenu} />}/>
                  <Route path = '/overdue-loans' element={<OverdueLoans selectedShowroomID={assignedShowroom} submenu={loansSubmenu} />}/>
                  <Route path = '/add-loan' element={<AddLoan selectedShowroomID={assignedShowroom} submenu={loansSubmenu}/>}/>
                  <Route path = '/return-sample' element={<ReturnSample selectedShowroomID={assignedShowroom} submenu={loansSubmenu}/>}/>
                  <Route path = '/loan/:loanID' element={<LoanDetails selectedShowroomID={assignedShowroom} />}/>
                  <Route path = '/admin/users' element={<ShowroomUsers selectedShowroom={{name: selectedShowroomLabel, id: assignedShowroom}} submenu={adminSubmenu} />}/>
                  <Route path = '/admin/showrooms' element={loggedInUserIsAdmin ? <Showrooms submenu={adminSubmenu} /> : <AccessRestrictedPage />} />
                  <Route path = '/admin/showroom/:showroomID' element={loggedInUserIsAdmin ? <EditShowroom submenu={adminSubmenu} /> : <AccessRestrictedPage />} />
                  <Route path = '/print-label' element={<PrintLabel selectedShowroomID={assignedShowroom} />} />
                  <Route path = "*" element={<ErrorPage/>} />
                </Routes>
              )}
            </div>
          </Router>
        </div>
        <CssBaseline />   

        <div className="footer">
            <BodyText style={{ textAlign: "center" }}>
                Need Help?{" "}
                <InlineLink
                  style={{ color: "blue" }}
                  href="https://hennesandmauritz.sharepoint.com/sites/PACorporateSupport/Shared%20Documents/PT%20External%20Communication/Showroom%20Application%20in%20Cloud_ExtCollabs/UserGuide/UserGuide_PPT%20version%20Ongoing.pdf?CT=1666893873415&OR=ItemsView"
                  target="_blank"
                > Download user guide
              </InlineLink>
            </BodyText>
        </div>             
      </ThemeProvider>
    </>  
  ); 
}

