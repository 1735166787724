import React from 'react';
import { useNavigate } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { HeadingXSmall, BodyText, IconButton, BulletList } from "@hm-group/fabric-components/brand/hm";
import { EditIcon } from "@hm-group/fabric-icons";

const ShowroomList = ({ showrooms }) => {
  const navigate = useNavigate();
  
  return (
  <TableContainer component={Paper}>
    <Table aria-label="Showrooms">
      <TableHead>
        <TableRow>
          <TableCell width="10%"><HeadingXSmall>Showroom</HeadingXSmall></TableCell>
          <TableCell width="10%" align="center"><HeadingXSmall>Loan days</HeadingXSmall></TableCell>
          <TableCell width="20%"><HeadingXSmall>Lent samples instructions</HeadingXSmall></TableCell>
          <TableCell width="35%"><HeadingXSmall>Contact info</HeadingXSmall></TableCell>
          <TableCell width="20%"><HeadingXSmall>Optional info</HeadingXSmall></TableCell>
          <TableCell width="5%"></TableCell>
        </TableRow>
      </TableHead>

      {showrooms && showrooms.length > 0 ? (
        <TableBody>
        {showrooms.map((showroom) => (
          <TableRow
            key={showroom.showroomId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell width="10%"><BodyText>{showroom.showroomName}</BodyText></TableCell>
            <TableCell width="10%" align="center"><BodyText>{showroom.loanPeriodDays?.toString()}</BodyText></TableCell>
            <TableCell width="20%"><BodyText dangerouslySetInnerHTML={{__html: showroom.lentSamplesInstructions}} /></TableCell>
            <TableCell width="15%">
              <BulletList
                items={[
                  `Phone number: ${showroom.phoneNumber || ''}`,
                  `Email: ${showroom.email || ''}`,
                  `Address: ${showroom.address || ''}`,
                ]}
              />
            </TableCell>
            <TableCell width="20%"><BodyText dangerouslySetInnerHTML={{__html: showroom.additionalInfo}} /></TableCell>
            <TableCell width="5%" align="right">
              <IconButton
                iconElement={<EditIcon size='small' />}
                onClick={() => navigate('/admin/showroom/'+String(showroom.showroomId))}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell>
              <BodyText>No showrooms.</BodyText>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  </TableContainer>
)};

export default ShowroomList;
