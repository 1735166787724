import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Stack } from '@mui/material';
import { Container } from "@mui/material";
import { BodyText, HeadingMedium, HeadingSmall } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';
import AddSamplesToLoan from './AddSamplesToLoan';
import SamplesList from './SamplesList';

export default function LoanDetails({ selectedShowroomID }) {
  const [shouldShowLoanPage, setShouldShowLoanPage] = useState();
  const [sampleList, setSampleList] = useState([]);
  const [loanInfo, setLoanInfo] = useState({});

  const {loanID} = useParams();

  function formatDate(date) {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  }

  // Get Loan data on load
  useEffect( () => {
    const getLoanData = () => {
      instance
      .get('/loan/getAllLoans', { params: { 'loanId': loanID }})
      .then(response => {
        setLoanInfo({
          loanID: loanID,
          showroomID: response.data.content[0].showroomId,
          createdDate: formatDate(response.data.content[0].createdDate),
          createdBy:response.data.content[0].createdById,
          updatedDate: response.data.content[0].updatedDate ? formatDate(response.data.content[0].updatedDate) : null,
          updatedBy: response.data.content[0].updatedById,
          lastReturnDate: formatDate(response.data.content[0].lastReturnDate),
          contactID: response.data.content[0].contactId,
          contactName: `${response.data.content[0].firstName} ${response.data.content[0].lastName}`,
          receiptSentDate: response.data.content[0].loanReceiptSentDate ? formatDate(response.data.content[0].loanReceiptSentDate) : null,
        });

        setSampleList(response.data.content[0].samples.filter(sample => sample.loanSample.sampleStatudId !== 4).reverse());
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getLoanData();
  },[loanID])

  useEffect( () => {
    if (loanInfo.showroomID && loanInfo.showroomID.toString() === selectedShowroomID.toString()) {
      setShouldShowLoanPage(true);
    } else {
      setShouldShowLoanPage(false);
    }
  },[loanInfo.showroomID, selectedShowroomID])

  // Refresh loan data
  const refreshLoanData = () => {
    instance
    .get('/loan/getAllLoans', { params: { 'loanId': loanID  }})
    .then(response => {
      setLoanInfo({
        ...loanInfo,
        updatedDate: response.data.content[0].updatedDate ? formatDate(response.data.content[0].updatedDate) : null,
        updatedBy: response.data.content[0].updatedById,
        lastReturnDate: formatDate(response.data.content[0].lastReturnDate),
        receiptSentDate: response.data.content[0].loanReceiptSentDate ? formatDate(response.data.content[0].loanReceiptSentDate) : null,
      });

      setSampleList(response.data.content[0].samples.filter(sample => sample.loanSample.sampleStatudId !== 4).reverse());
    })  
    .catch(error => {
      console.log(error);
    })
  }

  if (loanInfo.showroomID) {
    return (
      <Container className='tabBox' maxWidth={false} style={{ paddingBottom: '24px' }}>
        {!shouldShowLoanPage ? (
          <div style={{paddingTop: '40px', textAlign: 'center'}}>
            <HeadingMedium isMarginless>Loan does not belong to the chosen showroom</HeadingMedium>
            <BodyText>Please choose a different showroom from the dropdown above.</BodyText>
          </div>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="15px 25px"
              marginX="-25px"
              style={{ background: 'rgb(243, 237, 232)' }}
            >
              <div>
                <HeadingSmall isMarginless>Loan no. {loanID}</HeadingSmall>
                <HeadingSmall isMarginless Tag="p">Contact name: {loanInfo.contactName}</HeadingSmall>
              </div>
              <div>
                <BodyText>
                  Created: {loanInfo.createdDate} by {loanInfo.createdBy}<br/>
                  Last updated: {loanInfo.updatedDate ? `${loanInfo.updatedDate} by ${loanInfo.updatedBy}` : '-'}
                </BodyText>
                <BodyText>
                  Last return date: {loanInfo.lastReturnDate}
                </BodyText>
              </div>
            </Stack>
            
            <AddSamplesToLoan
              loanID={loanInfo.loanID}
              selectedShowroomID={selectedShowroomID}
              refreshLoanData={refreshLoanData}
              style={{ marginTop: '36px', marginBottom: '24px'}}
            />

            <SamplesList
              title="Added Samples"
              samples={sampleList}
              refreshLoanData={refreshLoanData}
              loanInfo={loanInfo}
              selectedShowroomID={selectedShowroomID}
            />
          </>
        )}
      </Container>
    );
  } else {
    return null;
  }
}
