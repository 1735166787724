import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Autocomplete, TextField } from '@mui/material';
import { BodyText, HeadingSmall, Modal, PrimaryButton, SecondaryButton } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';
import PageContainer from '../../components/PageContainer';

export default function AddLoan({ selectedShowroomID, submenu }) {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState();
  const [showUpdatedOneYearAgoModal, setShowUpdatedOneYearAgoModal] = useState(false);

  const navigate = useNavigate();
 
  useEffect( () => {
    const loadContacts = async() => {
      const response = await instance.get("/getContacts/"+selectedShowroomID);
      setContacts(response.data.map(contact => ({ 
        value:contact.contactId, 
        label: `${contact.firstName} ${contact.lastName}`
      })));
    };

    loadContacts();
  },[selectedShowroomID])

  function createNewLoan() {
    instance
      .post("/createLoanNumber/"+ selectedContact.value+"/"+selectedShowroomID)
      .then(response => {
        if(response.data.UpdatedOneYearAgo === 'false') {
          navigate('/loan/'+String(response.data.loanNumber));
        } else {
          setShowUpdatedOneYearAgoModal(response.data.UpdatedOneYearAgo);
        }
      })
      .catch(error => {
        console.log(error);
      }) 
  }

  return (
    <>
      {showUpdatedOneYearAgoModal && (
        <Modal
          isOpen={showUpdatedOneYearAgoModal}
          heading="Can't create Loan"
          content={
            <>
              <BodyText>
                You can't create loan for {selectedContact.label}.
              </BodyText>
              <BodyText>The Contact's information is either incomplete or has not been updated for over a year. Due to GDPR reason the contact information needs to be updated.</BodyText>
              <BodyText>Please, review and update the contact.</BodyText>
            </>
          }
          closeLabel="Close"
          size="medium"
          onClose={() => setShowUpdatedOneYearAgoModal(!showUpdatedOneYearAgoModal)}
          actions={
            <>
              {/* to do - navigate works, but loans item is still selected in the header menu. fix this. */}
              <PrimaryButton onClick={() => navigate('/contact/'+String(selectedContact.value))}>Go to Contact</PrimaryButton>
              <SecondaryButton onClick={() => setShowUpdatedOneYearAgoModal(false)}>Cancel</SecondaryButton>
            </>
          }
        />
      )}

      <PageContainer title="Add loan" submenu={submenu}>
        <Stack direction="column" spacing={3} style={{ width: '30%'}}>
          <HeadingSmall>Create loan for contact</HeadingSmall>

          <Autocomplete
            disablePortal
            id="contacts"
            disabled={contacts.length === 0 ? true : false}
            value={selectedContact ? selectedContact.label : ''}
            options={contacts}
            renderOption={(props, option) => (
              <li {...props} key={option.label}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} className="hm-text-field" required placeholder="Search for contact" />
            )}
            onChange={(event, value) => setSelectedContact(value)}
          />

          <PrimaryButton
            onClick={() => createNewLoan()}
            type="submit"
            isDisabled={!selectedContact}
          >
            Create Loan
          </PrimaryButton>
        </Stack>
      </PageContainer>
    </>
  )
}