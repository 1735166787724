import React from 'react';

import { TextArea } from "@hm-group/fabric-components/brand/hm";

const TextareaWithLimitedLength = ({ value, name, label, maxLength, updateState }) => {
  return (
    <TextArea
      value={value}
      name={name}
      label={label}
      onChange={updateState}
      maxLength={maxLength}
      isInvalid={value.length > maxLength}
      helperText={`The text may not be longer than ${maxLength} characters. It is currently ${value.length} characters long.`}
    />
  );
}

export default TextareaWithLimitedLength;