import React from 'react';
import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import Authenticate from './Authenticate';
import { msalInstance } from './AuthConfig';

// eslint-disable-next-line react/prop-types
export default function AuthWrapper({ children }) {
    // Default to using the first account if no account is active on page load
    if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
    ) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.

        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback(event => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
            event.payload.account
        ) {
            const { account } = event.payload;
            msalInstance.setActiveAccount(account);
            localStorage.setItem('access_token', event.payload.accessToken);
        }
    });

    // This will set roles from the token
    return (
        <MsalProvider instance={msalInstance}>
            <Authenticate>
                <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {/* Sign in required! */}
                </UnauthenticatedTemplate>
            </Authenticate>
        </MsalProvider>
    );
}
