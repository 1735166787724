import React, {useState, useEffect} from 'react';

import { Stack, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

import { SecondaryButton, IconButton } from "@hm-group/fabric-components/brand/hm";
import { TrashIcon, PlusIcon } from "@hm-group/fabric-icons";

export default function OptionalShowrooms({
  allShowroomsList,
  existingShowrooms,
  updateUserInfo,
  defaultShowroomChosen,
}) {
  const [optionalShowrooms, setOptionalShowrooms] = useState(existingShowrooms ? existingShowrooms : []);

  useEffect(() => {
    updateUserInfo(optionalShowrooms);
  },[optionalShowrooms]);

  return (
    <>
      {[...Array(optionalShowrooms.length).keys()].map(i => {
        return (
          <Stack direction="row" spacing={1}>
            {/* For now this will show the list of all the showrooms and it's on admin to make sure that there is no repetition between fields */}
            <div style={{ flexGrow: '1'}}>
              <FormControl sx={{ width: '100%' }} >
                <InputLabel id="defaultShowroom-label">Showroom</InputLabel>
                <Select
                  id="defaultShowroom"
                  label="Showroom"
                  value={optionalShowrooms ? optionalShowrooms[i] : ''}
                  onChange={e => setOptionalShowrooms([...optionalShowrooms].fill(e.target.value, i, i+1))}
                >
                  {allShowroomsList.map(showroom => <MenuItem value={showroom.value}>{showroom.label}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            
            <IconButton
              iconElement={<TrashIcon />}
              onClick={() => { setOptionalShowrooms(optionalShowrooms => optionalShowrooms.filter((s, index) => index !== i)) }}
            />
          </Stack>
        )
      })}
      <SecondaryButton
        onClick={() => setOptionalShowrooms(optionalShowrooms.concat(''))}
        type="button"
        style={{ marginTop: '40px'}}
        isDisabled={!defaultShowroomChosen}
        iconElement={<PlusIcon size='small' />}
      >
        Assign access to another showroom
      </SecondaryButton>
    </>   
  );
}

