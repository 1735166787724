import React from 'react';
import { Link as NavLink } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { HeadingXSmall, BodyText, InlineLink } from "@hm-group/fabric-components/brand/hm";

const ContactsList = ({
  contacts,
  mediaLabels
}) => {
  const replaceAmp = (value) => {
    let newValue;

    if (value) {
      if (value[value.length - 1] === '&') {
        newValue = value.slice(0, - 1).replaceAll('&', ', ')
      } else {
        newValue = value.replaceAll('&', ', ')
      }
    }

    return newValue;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Contacts">
        <TableHead>
          <TableRow>
            <TableCell width="12%"><HeadingXSmall>Contact</HeadingXSmall></TableCell>
            <TableCell width="12%"><HeadingXSmall>Media</HeadingXSmall></TableCell>
            <TableCell width="10%"><HeadingXSmall>Media label</HeadingXSmall></TableCell>
            <TableCell width="10%"><HeadingXSmall>Occupation</HeadingXSmall></TableCell>
            <TableCell width="10%"><HeadingXSmall>Interest</HeadingXSmall></TableCell>
            <TableCell width="12%"><HeadingXSmall>Phone</HeadingXSmall></TableCell>
            <TableCell width="18%"><HeadingXSmall>Email</HeadingXSmall></TableCell>
            <TableCell width="16%"><HeadingXSmall>URL</HeadingXSmall></TableCell>
            <TableCell><HeadingXSmall>Additional information</HeadingXSmall></TableCell>
          </TableRow>
        </TableHead>

        {contacts && contacts.length > 0 ? (
          <TableBody>
          {contacts.map((contact) => (
            <TableRow
              key={contact.contactId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell width="12%"><NavLink to={'/contact/'+String(contact.contactId)}><BodyText>{contact.firstName} {contact.lastName}</BodyText></NavLink></TableCell>
              <TableCell width="12%"><NavLink to={'/media/'+String(contact.mediaId)}><BodyText>{contact.media && contact.media.mediaName}</BodyText></NavLink></TableCell>
              <TableCell width="10%">
                {contact.media && mediaLabels.map(mediaLabel => (contact.media.mediaLabel === mediaLabel.mediaLabelId) &&
                  <BodyText>{mediaLabel.mediaLabelName}</BodyText>
                )}
              </TableCell>
              <TableCell width="10%"><BodyText>{replaceAmp(contact.contactlabelId)}</BodyText></TableCell>
              <TableCell width="10%"><BodyText>{replaceAmp(contact.interestedInId)}</BodyText></TableCell>
              <TableCell width="12%"><BodyText>{contact.phone}</BodyText></TableCell>
              <TableCell width="18%"><InlineLink href={`mailto: ${contact.email}`}>{contact.email}</InlineLink></TableCell>
              <TableCell width="16%"><BodyText>{contact.url}</BodyText></TableCell>
              <TableCell><BodyText>{contact.additionalInfo}</BodyText></TableCell>
            </TableRow>
          ))}
        </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={8}>
                <BodyText>There are no contacts that match the filter criteria.</BodyText>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
)};

export default ContactsList;