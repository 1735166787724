import './App.css';
import { React, useState, useEffect } from "react";
import instance from './services/UserService';
import App2 from './App2';
import { ContextWrapper } from "./services/state";
import RequestInterceptor from './auth/requestInterceptor';
import { useMsal } from "@azure/msal-react";
import { InlineLink } from "@hm-group/fabric-components/brand/hm";

function App() {

	const [isValid, setIsValid] = useState('');
	const { accounts } = useMsal();
	const email = accounts[0].username;

	useEffect( () => {
		const loadUser = async() => {
		  const response = await instance.post("/checkUserExist",{userName: email, accessToken: localStorage.getItem('access_token')});
		  localStorage.setItem('user_id', response.data.user_id);
		  localStorage.setItem('user_unique_name', response.data.user_unique_name);
		  localStorage.setItem('user_role', response.data.role);
		  setIsValid(response.data.role);
		};
		loadUser();
	  },[]) 
 
	return (
		<div className="App">
			<ContextWrapper>
				<RequestInterceptor>
					<>
					{ (isValid === 1 || isValid === 2) && <App2 /> }	
					{ (isValid === null || isValid === 0 || isValid === undefined) &&
						<div style={{backgroundColor: '#ff8f8f', padding: '20px', margin: '20px', textAlign: 'center'}}>
							Access Denied. You don't have access to Showroom Application.
							To request access please contact Showroom responsible.
							See "Contact Head Office" on {" "} 
							<InlineLink
								href="https://inside.hmgroup.com/content/inside/en/our-company/organisation/communications/h-m-brand-communications/showroom-contacts1.html"
								target="_blank"
							>
								H&M Inside
							</InlineLink>.
						</div>
					}
					</>
				</RequestInterceptor>
			</ContextWrapper>
		</div>
	);
}
export default App;