import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import axios from "axios";
import { loginRequest, graphScopes } from './AuthConfig';


// eslint-disable-next-line react/prop-types
const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async config => {
        const { url } = config;
        
        let response = null;

        if (!account) {
            throw Error("No active account! Verify a user has been signed in.");
        }
       // if (url.includes("graph.microsoft.com")) {
         //   response = await instance.acquireTokenSilent({
           //     ...graphScopes,
             //   account,
            //});
        //} else
            response = await instance.acquireTokenSilent({
                ...loginRequest,
                account,
            });

        const bearer = `Bearer ${response.accessToken}`;
        console.log("bearer "+bearer);
        config.headers.Authorization = bearer;

        return config;
    });
    /* eslint-enable no-param-reassign */

    return <>{children}</>;
};

export default RequestInterceptor;
