import React from 'react';
import { useBarcode } from 'next-barcode';

const Barcode = ({ value }) => {
  const { inputRef } = useBarcode({
    value: value ? value : 'xxxxx',
    options: {
      fontSize: 25,
      height: 60,
      margin: 0
    }
  }); 

  return (
    <svg ref={inputRef} alt="barcode"/>
  );
}

export default Barcode;

