import React, {useState, useEffect} from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { Tooltip, Stack, Select, MenuItem, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { IconButton } from '@material-ui/core';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { HeadingMedium, BannerNotification, Spinner, PrimaryButton, NumberInput, TextInput } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';

export default function AddSamplesToLoan({ loanID, selectedShowroomID, refreshLoanData, style }) {
  const [sections, setSections] = useState();
  const [departments, setDepartments] = useState();
  const [sampleData, setSampleData] = useState({});
  const [withProductNoSearch, setWithProductNoSearch] = useState(true);
  const [scan, setScan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWrongProductNoError, setShowWrongProductNoError] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [showBarCodeScanError, setShowBarCodeScanError] = useState(false);
  const [seasonNotProvided, setSeasonNotProvided] = useState(false);
  const [wrongSeasonError, setWrongSeasonError] = useState(false);
  const [showDataMissingError, setShowDataMissingFromProductService] = useState(false);
  const [internalErrorOccured, setInternalErrorOccured] = useState(false);
  const [enableAddSampleButton, setEnableAddSampleButton] = useState(false);
  
  useEffect( () => {
    const getSeasons = async() => {
      const response = await instance.get("/getSeasons");
      setSeasons(response.data.map(season => ({
        id: season.seasonId,
        label: season.seasonName,
      })));
    };

    getSeasons();
  },[])
  
  useEffect( () => {
    const getSections = async() => {
      const response = await instance.get("/getSections");
      const hmSections = response.data.filter(section => section.CorporateBrandId === 0).map(section => ({
        value: section.SectionId,
        label: section.SectionName,
      }));
      setSections(hmSections);
    };

    getSections();
  },[])
  
  function getDepartments(sectionID){
    setLoading(true);

    instance
    .post("/getDepartments/"+ sectionID)
    .then(response => {
      setDepartments(response.data.map(department => ({
        id:department.DepartmentId,
        label:department.DepartmentName
      })));
    })
    .catch(error => {
    })

    setLoading(false);
	}

    // Enable Add sample button
    useEffect(() => {
      if((sampleData.productNumber) || (sampleData.sectionID && sampleData.departmentName && sampleData.sampleInformation)) {
        setEnableAddSampleButton(true);
      } else {
        setEnableAddSampleButton(false);
      }
    }, [sampleData]);

  const handleCloseStatusMessage = () => {
    setShowWrongProductNoError(false);
    setShowBarCodeScanError(false);
    setSeasonNotProvided(false);
    setWrongSeasonError(false);
    setInternalErrorOccured(false);
  }

  function FetchProductDetails (){
    setEnableAddSampleButton(false);
    setWrongSeasonError(false);
    setSeasonNotProvided(false);
    setShowWrongProductNoError(false);
    setShowDataMissingFromProductService(false);
    setInternalErrorOccured(false);

    instance.get("/products/"+sampleData.productNumber+"/"+loanID+"/"+selectedShowroomID+"/"+2+"/"+sampleData.season)
      .then(response => {
        refreshLoanData();
        setLoading(false);
        setShowWrongProductNoError(false);
        setShowBarCodeScanError(false);
        setSeasonNotProvided(false);
        setSampleData({});
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          if(error.response.data[0] === 'InvalidSeason'){
            setWrongSeasonError(true);
          }else if(error.response.data[0] === 'InvalidProductNumber'){
            setShowWrongProductNoError(true);
          } else if(error.response.data[0] === 'BaseFactObjectisNull'){
            setShowDataMissingFromProductService(true);
          } else {
            setInternalErrorOccured(true);
          }
          setLoading(false);
        }
      });
    }

  function handleBarCodeInput(event){
    setLoading(true);
    setSeasonNotProvided(false);
    
    instance.post("/barcode/"+sampleData.productNumber+"/"+loanID+"/"+selectedShowroomID+"/"+2)
    .then(response => {
      refreshLoanData();
      setLoading(false);
      setShowBarCodeScanError(false);
      setSeasonNotProvided(false);
      setSampleData({});
    })
    .catch(error => {
      setLoading(false);
      setShowBarCodeScanError(true);
      setSeasonNotProvided(false);
    });
  }

  const handleBarCodeScanner = (error, result) => {
    if (result) {
      setSampleData({...sampleData, productNumber: result.text})
      window.navigator.vibrate(100);
      setScan(false);
      setShowBarCodeScanError(false);
    }
    else {
      setShowBarCodeScanError(true);
    }
  }

  const addSample = () => {
    if(withProductNoSearch && !sampleData.season && sampleData.productNumber.length === 7){
      setSeasonNotProvided(true);
    } else {
      setLoading(true);
      setEnableAddSampleButton(false);
  
      if(withProductNoSearch){
        if(sampleData.productNumber.length >= 9){
          setWrongSeasonError(false);
          setSeasonNotProvided(false);
          setShowWrongProductNoError(false);
          handleBarCodeInput();
        } else{
          FetchProductDetails();
        }
      } else {
        CreateLoansWithoutProductNo();
      }
    }
  }

  function CreateLoansWithoutProductNo() {
    var sampleDetails = {      
      "sectionName": sampleData.sectionName ? sampleData.sectionName : '',
      "departmentName": sampleData.departmentName ? sampleData.departmentName : '',
      "information": sampleData.sampleInformation ? sampleData.sampleInformation : '',
      "showroomId": selectedShowroomID
    }

    instance
    .post('/createLoans'+"/"+loanID+"/"+2, sampleDetails, {
      "headers": {
        "content-type": "application/json",
        }
    })		
    .then(response => {
        refreshLoanData();
        setSampleData({});
    })
    .catch(error => {
      console.log(error);
    })

    setLoading(false);
  }

  return (
    <div style={style}>
      <Spinner isRendered={loading}/>

      <HeadingMedium>Add Samples to loan</HeadingMedium>

      <RadioGroup
        row
        className="hmBodyText"
        aria-labelledby="add-sample-options"
        defaultValue="withProductNumber"
        name="add-sample-options"
        onChange={e => {
          if (e.target.value === 'withProductNumber') {
            setWithProductNoSearch(true);
          } else {
            setWithProductNoSearch(false);
          }

          setSampleData({});
        }}
      >
        <FormControlLabel value="withProductNumber" control={<Radio color="default" />} label="With Product No." />
        <FormControlLabel value="withoutProductNumber" control={<Radio color="default" />} label="Without Product No." />
      </RadioGroup>

      {withProductNoSearch && (
        <div>  
          <Stack alignItems='baseline' direction={{ xs: 'column', sm: 'row' }} spacing={2} marginY={1}>
            <NumberInput
              placeholder='Barcode / Product No.'
              helperText='Enter barcode or 7 digit Product No.'
              name="product-number"
              value={sampleData.productNumber || ''}
              onChange={e => setSampleData({...sampleData, productNumber: e.target.value})}
            />

            <FormControl sx={{ minWidth: 220 }}>
              <InputLabel id="season-select-label">Season</InputLabel>
              <Select
                id="season-select"
                label="Season"
                value={sampleData.season ? sampleData.season : ''}
                onChange={e => setSampleData({...sampleData, season: e.target.value})}
              >
                {seasons.map(season => <MenuItem key={season.id} value={season.label}>{season.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack> 

          <Tooltip title="Click here for Scanning barcode" placement="right-end">
            <IconButton onKeyDown={addSample} onClick={(e) => setScan(true)}>
                <QrCodeScannerIcon/>
            </IconButton>
          </Tooltip>

          {scan && (
            <>
              <BarcodeScannerComponent
                width={500}
                height={500}
                onUpdate={handleBarCodeScanner}/>
              <p>{sampleData.productNumber}</p>
            </>
          )}
            
          {seasonNotProvided && (
            <BannerNotification 
              severity="error"
              message="Please select proper season for Product numbers."
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}

          {showWrongProductNoError && (
            <BannerNotification 
              severity="error"
              message="Invalid or incorrect Product Number/Barcode. Check if you have entered the correct numbers."
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}

          {showBarCodeScanError && (
            <BannerNotification 
              severity="error"
              message="Barcode Not Found, Please rescan."
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}

          {wrongSeasonError && (
            <BannerNotification 
              severity="error"
              message="There is no data present for this season, please select a proper current season"
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}

          {showDataMissingError && (
            <BannerNotification 
              severity="error"
              message="Few blocks of data missing from Product service API"
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}

          {internalErrorOccured && (
            <BannerNotification 
              severity="error"
              message="Sample creation failed due to some internal error occurred"
              closeLabel="Close"
              // onCloseButtonClick={(event) => {handleCloseStatusMessage(event)}}>  
            />
          )}
        </div>
      )}

      {!withProductNoSearch && (
        <Stack direction="column" spacing={2} style={{ width: '30%'}} marginY={2}>
          <FormControl sx={{ minWidth: 120 }} >
            <InputLabel id="section-select-label">Section</InputLabel>
            <Select
              id="section-select"
              label="Section"
              value={sampleData.sectionID ? sampleData.sectionID : ''}
              onChange={(event) => {
                sections.forEach(section => {
                  if(section.value === event.target.value) {
                    setSampleData({
                      ...sampleData,
                      sectionID: event.target.value,
                      sectionName: section.label,
                      departmentName: '',
                    })
                  }
                })

                // setSampleData({
                //   ...sampleData,
                //   sectionID: event.target.value,
                //   sectionName: event.target.name,
                //   departmentName: '',
                // })

                getDepartments(event.target.value);
              }}
            >
              {sections.map(section => <MenuItem key={section.value} value={section.value} name={section.label}>{section.label}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }} >
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              id="department-select"
              label="Department"
              // disabled={!departments}
              value={sampleData.departmentID ? sampleData.departmentID : ''}
              onChange={e => {
                // setSampleData({
                //   ...sampleData,
                //   departmentID: e.target.value,
                //   departmentName: e.target.name
                // })}
                departments.forEach(department => {
                  if(department.id === e.target.value) {
                    setSampleData({
                      ...sampleData,
                      departmentID: e.target.value,
                      departmentName: department.label
                    })
                  }
                })
              }}
            >
              {departments ? departments.map(department => <MenuItem key={department.id} value={department.id} name={department.label}>{department.label}</MenuItem>) : <MenuItem value="" name="">No departments found</MenuItem>}
            </Select>
          </FormControl>

          <TextInput
            placeholder="Sample Info"
            name="sample-information"
            value={sampleData.sampleInformation || ''}
            onChange={e => setSampleData({ ...sampleData, sampleInformation: e.target.value })}
            isMarginless
          />
        </Stack> 
      )}

      <PrimaryButton
        onClick={() => addSample()}
        type="submit"
        isDisabled={!enableAddSampleButton}
      >
        Add Sample
      </PrimaryButton>
    </div>
  );
}
