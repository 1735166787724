import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        authority:
        'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
        clientId: 'ae8a69d9-e183-4b93-b267-56e8d98d43cc',
        redirectUri: process.env.REACT_APP_PROTOCOL + `://${window.location.host}`+`/loan/default/1`,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['api://3082fb2f-acb8-479f-ab0d-72489bde9bbf/User.Read'],
};

export const graphScopes = {
    scopes: ['https://graph.microsoft.com/.default'],
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const msalInstance = new PublicClientApplication(msalConfig);
