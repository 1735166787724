import React from 'react'

import { HeadingLarge, Preamble } from "@hm-group/fabric-components/brand/hm";

export default function AccessRestrictedPage() {
    return (
        <div style={{ marginTop: '40px', textAlign: 'center'}}>
            <HeadingLarge>Access Restricted</HeadingLarge>
            <Preamble>You don't have permission to view this page.</Preamble>
        </div>
    )
}
