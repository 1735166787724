import React from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';

import { HeadingXSmall, BodyText, IconButton } from "@hm-group/fabric-components/brand/hm";
import { EditIcon } from "@hm-group/fabric-icons";

const UserList = ({
  loggedInUserIsAdmin,
  users,
  startUserDeletion,
  startUserEdit,
  changeOrderByCreationDate,
  allShowroomsList
}) => {
  function formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
  <TableContainer component={Paper}>
    <Table aria-label="Showroom users">
      <TableHead>
        <TableRow>
          <TableCell width="10%"><HeadingXSmall>First name</HeadingXSmall></TableCell>
          <TableCell width="13%"><HeadingXSmall>Last name</HeadingXSmall></TableCell>
          <TableCell width="7%"><HeadingXSmall>Alias</HeadingXSmall></TableCell>
          <TableCell width="18%"><HeadingXSmall>Email</HeadingXSmall></TableCell>
          <TableCell width="10%"><HeadingXSmall>Country</HeadingXSmall></TableCell>
          <TableCell width="18%"><HeadingXSmall>Showroom(s)</HeadingXSmall></TableCell>
          <TableCell width="12%">
            <Link
              component="button"
              onClick={() => {changeOrderByCreationDate()}}
            >
              <HeadingXSmall>Created on <span>&uarr;</span><span>&darr;</span></HeadingXSmall>
            </Link>
          </TableCell>
          {loggedInUserIsAdmin && (
            <TableCell width="12%"></TableCell>
          )}
        </TableRow>
      </TableHead>

      {allShowroomsList && users && users.length > 0 ? (
        <TableBody>
        {users.map((user) => (
          <TableRow
            key={user.userId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell width="10%"><BodyText>{user.firstName}</BodyText></TableCell>
            <TableCell width="13%"><BodyText>{user.lastName}</BodyText></TableCell>
            {/* User alias */}
            <TableCell width="7%"><BodyText>{user.userUniqueName}</BodyText></TableCell>
            {/* User email */}
            <TableCell width="18%"><BodyText>{user.userName}</BodyText></TableCell>
            <TableCell width="10%"><BodyText>{user.country}</BodyText></TableCell>
            <TableCell width="18%">
              {user.userShowrooms.map(userShowroom => (
                allShowroomsList.map(showroom => {
                  if(userShowroom.showroomId === showroom.value) {
                    return <BodyText>{`${showroom.label}${userShowroom.defaultShowroom === 1 ? ' (default)': ''}`}</BodyText>
                  }
                })
              ))}
            </TableCell>
            <TableCell width="12%"><BodyText>{user.createdDate ? formatDate(user.createdDate) : ''}</BodyText></TableCell>
            {loggedInUserIsAdmin && (
              <TableCell width="12%" align="right">
                {/* {loggedInUserIsAdmin && ( */}
                <IconButton
                  iconElement={<EditIcon />}
                  onClick={() => startUserEdit(user)}
                />
                {/* )} */}
                {/* <IconButton onClick={() => startUserDeletion(user.userId, `${user.firstName} ${user.lastName}`)}>
                  <TrashIcon />
                </IconButton> */}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell>
              <BodyText>No users.</BodyText>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  </TableContainer>
)};

export default UserList;
