import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from '@mui/material';

import { BodyText, HeadingXSmall, Modal, PrimaryButton, SecondaryButton, TextInput, InlineLink } from "@hm-group/fabric-components/brand/hm";
import { MailIcon } from "@hm-group/fabric-icons";

import instance from '../../services/UserService';

export default function SendReceipt({ samples, loanInfo, refreshLoanData, selectedShowroomID }) {
  const [contactEmailMissing, setContactEmailMissing] = useState(false); // former isEmailExist
  const [contactEmail, setContactEmail] = useState('');
  const [recipientEmails, setRecipientEmails] = useState('');
  const [contMediaName, setContMediaName] = useState('');
  const [contDeliveryAddress, setContDeliveryAddress] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [sendReceiptConfirm, setSendReceiptConfirm] = useState(false);
  const [loanFooterText1, setLoanFooterText1] = useState('');
  const [loanFooterText2, setLoanFooterText2] = useState('');
  const [loanFooterText3, setLoanFooterText3] = useState('');
  const [loanFooterText4, setLoanFooterText4] = useState('');
  const [showEmailTriggerFailure, setShowEmailTriggerFailure] = useState(false);
  const [showEmailTriggerSuccess, setShowEmailTriggerSuccess] = useState(false);
  const [showEmailContect, setShowEmailContect] = useState('');
  const [subject, setSubject] = useState('');

  const navigate = useNavigate();
  
  function handleOnClicktriggerEmail() {
    instance
    // to do - is there a better api for this? can we fetch the contact email at earlier stages?
    .get("/checkEmailExist/"+loanInfo.contactID)
    .then(response => {
      if(response.data.email === '') {
        setContactEmailMissing(true);
      } else {
        // setContactEmailMissing(false);
        setContactEmail(response.data.email);
        setRecipientEmails(response.data.mediaEmail ? response.data.email+", "+response.data.mediaEmail : response.data.email);
        setContMediaName(response.data.mediaName);
        setContDeliveryAddress(response.data.deliveryAddress);
        setContactPhone(response.data.phone);
        setSubject("Loan "+ loanInfo.loanID);
        setSendReceiptConfirm(true);
      }
    })
    .catch(error => {
        setShowEmailTriggerFailure(true);
    });

    instance
    .get("/getLoanText/"+selectedShowroomID)
    .then(response => {
      setLoanFooterText1(response.data.loan_footer_text1);
      setLoanFooterText2(response.data.loan_footer_text2);
      setLoanFooterText3(response.data.loan_footer_text3);
      setLoanFooterText4(response.data.loan_footer_text4);
    })
    .catch(error => {
      setShowEmailTriggerFailure(true);
    });
  }

  const onClickOfSendConfirmYes = e => {
    setShowEmailContect(true);
    setSendReceiptConfirm(false);
  }

  function triggerEmail() {
    var detailsForEmail = {
			"loanId": loanInfo.loanID,
			"contactId": loanInfo.contactID,
			"subject": subject,
			"emails": recipientEmails,
			"showroomId" : selectedShowroomID
    };

    instance
    .post("/sendEmail", detailsForEmail, {
      "headers": {
        "content-type": "application/json",
        }
    })
    .then(response => {
      refreshLoanData();
      setShowEmailContect(false);
      setShowEmailTriggerSuccess(true);
    })
    .catch(error => {
      setShowEmailTriggerFailure(true);
    });
  }

  return (
    <>
     {contactEmailMissing && (
        <Modal
          isOpen={contactEmailMissing}
          heading="Can't Send Receipt"
          content={
            <>
              <BodyText>You can't send a receipt to {loanInfo.contactName} since there are no email registered to the contact.</BodyText>
              <BodyText> Review the contact information to add an email address.</BodyText>
            </>
          }
          closeLabel="Close"
          size="medium"
          onClose={() => setContactEmailMissing(!contactEmailMissing)}
          actions={
            <>
              <PrimaryButton onClick={() => navigate('/contact/'+String(loanInfo.contactID))}>Go to Contact</PrimaryButton>
              <SecondaryButton onClick={() => setContactEmailMissing(false)}>Cancel</SecondaryButton>
            </>
          }
        />
      )}

      {sendReceiptConfirm && (
        <Modal
          isOpen={sendReceiptConfirm}
          heading="Send Receipt"
          closeLabel="Close"
          size="medium"
          onClose={() => setSendReceiptConfirm(!sendReceiptConfirm)}
          content={<BodyText> Do you want to send a receipt to {loanInfo.contactName} at {contactEmail}?</BodyText>}
          actions={
            <>
              <PrimaryButton onClick={onClickOfSendConfirmYes}>Yes</PrimaryButton>
              <SecondaryButton onClick={() => {setSendReceiptConfirm(false)}}>No</SecondaryButton>
            </>
          }
        />
      )}    

    {showEmailContect && (
      <Modal
        isOpen={showEmailContect}
        closeLabel="Close"
        size="medium"
        heading="Send Receipt"
        onClose={() => setShowEmailContect(!showEmailContect)}
        content={
          <>
            <Stack direction='row' spacing={2} alignItems='end' marginBottom={4} style={{ backgroundColor: '#faf6f2'}} paddingY={3} paddingX={2}>
              <Stack spacing={1} style={{ flexGrow: 2}}>
                <TextInput
                  label="From:"
                  disabled
                  value='noreply@hmgroupshowroom.com'
                  isMarginless
                />

                  <TextInput
                  label="To:"
                  value={recipientEmails}
                  onChange={e => setRecipientEmails(e.target.value)}
                  isMarginless
                />

                <TextInput
                  label="Subject:"
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  isMarginless
                />
              </Stack>
              <PrimaryButton
                type="submit"
                size="medium"
                onClick={triggerEmail}
              >
                Send   
              </PrimaryButton>
            </Stack>

            <Stack direction='row' spacing={4} marginBottom={3} marginTop={4}>
              <div>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Loan:</b> ${loanInfo.loanID}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Loan date:</b> ${loanInfo.createdDate}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Return date:</b> ${loanInfo.lastReturnDate}`}}/>
              </div>
              <div>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Name:</b> ${loanInfo.contactName}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Media:</b> ${contMediaName}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Delivery address:</b> ${contDeliveryAddress}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Email:</b> ${contactEmail}`}}/>
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Phone:</b> ${contactPhone}`}}/>
              </div>
            </Stack>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><HeadingXSmall>Product Details</HeadingXSmall></TableCell>
                    <TableCell><HeadingXSmall>Barcode</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Sample Information</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Section|Department</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>In Shop</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Price</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Composition</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Colors</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Channel</HeadingXSmall></TableCell>
                    <TableCell align="center"><HeadingXSmall>Sizes</HeadingXSmall></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {samples.map((sample) => 
                    (sample.loanSample.sampleStatudId === 2 ?
                    <TableRow
                      key={sample.sampleId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <BodyText>
                          {sample.productNumber ? `${sample.productNumber} ${sample.productClassification}` : '**'}
                        </BodyText>
                      </TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.barcode || '-'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.information || '-'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.sectionName} | {sample.departmentName}  
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.productNumber ? sample.inShopWeek : '**'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.productNumber ? `${sample.price} ${sample.currencyCode}` : '**'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.productNumber ? sample.composition : '**'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.productNumber ? sample.colors : '**'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText>
                        {sample.productNumber ? `${sample.channelOffline} ${sample.channelOnline}` : '**'}
                      </BodyText></TableCell>
                      <TableCell align="center"><BodyText style={{overflowWrap: 'break-word'}}>
                        {sample.productNumber ? sample.sizes : '**'}
                      </BodyText></TableCell>
                    </TableRow> : null
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <br/>

            <BodyText>* Varies since multiple colors and composition. Go to PST for more details.<br/>
            ** No info since no Product Number</BodyText>

            <br/>

            {loanFooterText1 && (
              <BodyText isMarginless dangerouslySetInnerHTML={{__html: loanFooterText1}}/>
            )}

            {loanFooterText2 && (
              <BodyText isMarginless dangerouslySetInnerHTML={{__html: loanFooterText2}}/>
            )}

            {loanFooterText3 && (
              <BodyText isMarginless dangerouslySetInnerHTML={{__html: loanFooterText3}}/>
            )}

            {loanFooterText4 && (
              <BodyText isMarginless dangerouslySetInnerHTML={{__html: loanFooterText4}}/>
            )}

            <br/>

            <BodyText>
              We register your personal data to our system to manage the use of showroom samples and existing media/business relations. For more information about our processing of personal data, please read <InlineLink 
                href="https://hmgroup.com/legal-notice/"
                target="_blank"
              >
                H&M Group Privacy Notice External Partners.
              </InlineLink >
            </BodyText>    
          </>
        }
      />
      )}

     {showEmailTriggerSuccess && (
        <Modal
          isOpen={showEmailTriggerSuccess}
          heading="SUCCESS"
          closeLabel="Close"
          size="medium"
          onClose={() => setShowEmailTriggerSuccess(!showEmailTriggerSuccess)}
          content={<BodyText>Receipt was sent to {loanInfo.contactName} at {contactEmail}.</BodyText>}
        />
      )}

      {showEmailTriggerFailure && (
        <Modal
          isOpen={showEmailTriggerFailure}
          heading="FAILURE"
          closeLabel="Close"
          size="medium"
          onClose={() => setShowEmailTriggerFailure(!showEmailTriggerFailure)}
          content={<BodyText>Some failure happened while triggering Email.</BodyText>}
        />
      )}

      <Stack direction="column" alignItems="end" spacing={1}>
        <SecondaryButton
          disabled={samples.length === 0}
          onClick={handleOnClicktriggerEmail}
          iconPosition="start"
          iconElement={<MailIcon size={('Icon size', 'small')} />}
        >
          Send Receipt   
        </SecondaryButton>

        <BodyText>
          Last sent receipt: {loanInfo.receiptSentDate ? loanInfo.receiptSentDate : "No receipt has been sent yet"}
        </BodyText>
      </Stack>
    </>
  )
}
