import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AuthWrapper from './auth/AuthWrapper';

ReactDOM.render(
 <AuthWrapper>
    <App />
  </AuthWrapper>,

document.getElementById('root'),

);