import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Stack, Grid } from "@mui/material";
import { PrimaryButton, TextInput, NumberInput, TextArea, HeadingSmall, BodyText } from "@hm-group/fabric-components/brand/hm";

import instance from '../../../services/UserService';
import PageContainer from '../../../components/PageContainer';

export default function EditShowroom({ submenu }) {
  const [showroomInfo, setShowroomInfo] = useState({});

  const {showroomID} = useParams();
  const navigate = useNavigate();

  // Get Showroom info on load
  useEffect(() => {
    const getShowroomInfo = () => {
      instance
      .get('/getAllShowrooms', { params: { 'showroomId': showroomID } })
      .then(response => {
        setShowroomInfo(response.data.content[0]);
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getShowroomInfo();
  },[showroomID])

  const saveChanges = () => {
    instance
    .put('/updateShowroom', showroomInfo)		
    .then(response => {
      navigate('/admin/showrooms', { state: `${showroomInfo.showroomName} showroom was successfully updated.`});
    })
    .catch(error => {
      console.log(error);
    })
  }

  return (
    <PageContainer
      title={`${showroomInfo.showroomName} showroom`}
      submenu={submenu}
    >
      <Grid container rowSpacing={2} columnSpacing={6}>
        <Grid item xs={12} md={5} lg={4}>
          <Stack direction="column" spacing={2}>
            <HeadingSmall>LENT SAMPLES INFO</HeadingSmall>

            <NumberInput
              isRequired
              label="Loan days"
              name="loanDays"
              onChange={e => setShowroomInfo({...showroomInfo, loanPeriodDays: e.target.value})}
              value={showroomInfo.loanPeriodDays || ''}
              helperText="Number of days in which the contact must return the sample to the showroom"
            />

            <TextArea
              value={showroomInfo.lentSamplesInstructions || ''}
              name="lentSamplesInstructions"
              label="Lent samples instructions"
              onChange={e => setShowroomInfo({...showroomInfo, lentSamplesInstructions: e.target.value})}
            />
          </Stack>
        </Grid>
        
        <Grid item xs={12} md={5} lg={4}>
          <Stack direction="column" spacing={2}>
            <HeadingSmall>CONTACT INFO</HeadingSmall>

            <BodyText>Contact info of the showroom or showroom responsible.</BodyText>

            <TextInput
              label="Phone number"
              name="phoneNumber"
              value={showroomInfo.phoneNumber || ''}
              placeholder='e.g. +1234567890'
              inputMode="tel"
              onChange={e => setShowroomInfo({...showroomInfo, phoneNumber: e.target.value})}
            />

            <TextInput
              label="Email"
              name="email"
              value={showroomInfo.email || ''}
              placeholder="e.g. example@email.com"
              inputMode="email"
              onChange={e => setShowroomInfo({...showroomInfo, email: e.target.value})}
            />

            <TextArea
              value={showroomInfo.address || ''}
              name="address"
              label="Address"
              onChange={e => setShowroomInfo({...showroomInfo, address: e.target.value})}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={5} lg={4}>
          <Stack direction="column" spacing={2}>
            <HeadingSmall>ADDITIONAL INFO</HeadingSmall>
            
            <BodyText>If there is any other information that needs to be shared with the contact, for legal or other purposes, please add it here.</BodyText>
            
            <TextArea
              value={showroomInfo.additionalInfo || ''}
              name="additionalInfo"
              label="Additional info"
              onChange={e => setShowroomInfo({...showroomInfo, additionalInfo: e.target.value})}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} style={{ paddingTop: '30px' }}>
        <PrimaryButton
          onClick={() => saveChanges()}
          type="button"
          isDisabled={!showroomInfo.loanPeriodDays}
        >
          Save changes
        </PrimaryButton>
      </Stack>
    </PageContainer>
  );
}
