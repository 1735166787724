import { Link as NavLink } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Link } from '@mui/material';

import { BodyText, HeadingXSmall } from "@hm-group/fabric-components/brand/hm";
import { BagIcon, ReturnsIcon, GiftCardIcon } from "@hm-group/fabric-icons";


const LoansList = ({ loans, changeOrderByCreationDate }) => {

  function formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
  <TableContainer component={Paper}>
    <Table aria-label="Loans">
      <TableHead>
        <TableRow>
          <TableCell width="10%"><HeadingXSmall>Loan No</HeadingXSmall></TableCell>
          <TableCell width="30%"><HeadingXSmall>Contact Name</HeadingXSmall></TableCell>
          <Tooltip disableFocusListener title="Lent">
            <TableCell width="8%" align='center'><BagIcon/></TableCell>
          </Tooltip>
          <Tooltip disableFocusListener title="Gift">
            <TableCell width="8%" align='center'><GiftCardIcon/></TableCell>
          </Tooltip>
          <Tooltip disableFocusListener title="Return">
            <TableCell width="8%" align='center'><ReturnsIcon/></TableCell>
          </Tooltip>
          <TableCell width='18%' align='center'>
            <Link
                component="button"
                onClick={() => {changeOrderByCreationDate()}}
              >
                <HeadingXSmall>Created <span>&uarr;</span><span>&darr;</span></HeadingXSmall>
              </Link>
              </TableCell>
          <TableCell width='18%' align='center'><HeadingXSmall>Return Date</HeadingXSmall></TableCell>
        </TableRow>
      </TableHead>

      {loans && loans.length > 0 ? (
        <TableBody>
        {loans.map((loan) => (
          <TableRow
            key={loan.loanId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell width="10%">
              <NavLink to={'/loan/'+String(loan.loanId)}>
                <BodyText>{loan.loanId.toString()}</BodyText>
              </NavLink>
            </TableCell>
            <TableCell width="30%"><BodyText>{loan.firstName +' '+loan.lastName}</BodyText></TableCell>
            <TableCell width="8%" align='center'>{loan.lent ? loan.lent : '0'}</TableCell>
            <TableCell width="8%" align='center'>{loan.gifted ? loan.gifted : '0'}</TableCell>
            <TableCell width="8%" align='center'>{loan.returned ? loan.returned : '0'}</TableCell>
            <TableCell width='18%' align='center'><BodyText>{loan.createdDate ? formatDate(loan.createdDate) : ''}</BodyText></TableCell>
            <TableCell width='18%' align='center'><BodyText>{loan.lastReturnDate ? formatDate(loan.lastReturnDate) : ''}</BodyText></TableCell>
          </TableRow>
        ))}
      </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell>
              <BodyText>No results.</BodyText>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  </TableContainer>
)};

export default LoansList;