import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
const AppContext = createContext();

export function ContextWrapper({ children }) {
    const pageTitle = useState('');
    const token = useState(null);

    const sharedState = {
        pageTitle,
        token,
    };

    return (
        <AppContext.Provider value={sharedState}>
            {children}
        </AppContext.Provider>
    );
}

ContextWrapper.propTypes = {
    children: PropTypes.node,
};

export function useAppContext() {
    return useContext(AppContext);
}
