import React, {useState, useEffect} from 'react';

import { TablePagination } from '@material-ui/core';

import instance from '../../services/UserService';
import PageContainer from '../../components/PageContainer';
import LoansList from './LoansList';

export default function OverdueLoans({ selectedShowroomID, submenu }) {
  const [loanList, setLoanList] = useState({totalElements: 0});
  const [orderByCreationDate, setOrderByCreationDate] = useState('ASC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateToday, setDateToday] = useState();

  // Get loans whenever the props change
  useEffect(() => {
    if (selectedShowroomID) {
      const getLoans = () => {
        instance
        .get('/loan/getAllLoans', {
          params: {
            'showroomId': parseInt(selectedShowroomID),
            'emptyLoan': false,
            'lent': 'ne(0)',
            'lastReturnDate': dateToday ? 'lt('+dateToday+')' : null,
            'size': rowsPerPage,
            'page': page,
            'sort': 'createdDate,'+orderByCreationDate,
          }
        })
        .then(response => {
          setLoanList(response.data);  
        })  
        .catch(error => {
          console.log(error);
        })
      }
  
      getLoans();
    }
  },[orderByCreationDate, page, rowsPerPage, selectedShowroomID, dateToday])

  useEffect(() => {
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    setDateToday([month, day, year].join('/'));
  }, [])

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Creation date
  const changeOrderByCreationDate = () => {
    setOrderByCreationDate(orderByCreationDate === 'DESC' ? 'ASC' : 'DESC');
  }

  return (
    <PageContainer title="Overdue loans" submenu={submenu}>
      <LoansList loans={loanList.content} changeOrderByCreationDate={changeOrderByCreationDate} />

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={loanList.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PageContainer>
  );
}