import React from "react";

import { Container } from "@material-ui/core";
import { Stack } from "@mui/material";

import { GhostLink, HeadingMedium } from "@hm-group/fabric-components/brand/hm";

export default function PageContainer({ title, submenu, children }) {
  return (
    <>
      {title && (
        <Container style={{paddingTop: '15px', paddingBottom: '5px'}}>
          <Stack direction='row' justifyContent='space-between' alignItems='end' height='45px'>
            <HeadingMedium>{title}</HeadingMedium>
            {submenu && (
              <Stack direction='row' spacing={4}>
                {submenu.map(item => (
                  <GhostLink
                    href={item.url}
                    key={item.url}
                    iconElement={item.icon ? item.icon : null}
                    iconPosition="start"
                  >
                    {item.label}
                  </GhostLink>
                ))}
              </Stack>
            )}
          </Stack>
        </Container>
      )}
      
      <Container
        className='tabBox'
        style={{
          paddingTop: '24px',
          paddingBottom: '24px',
        }}
      >
        {children}
      </Container>
    </>
  );
}
