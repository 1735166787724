import axios from 'axios';

/** base URL to to make requestes to the Showroom dasbase and APIs */
const instance = axios.create({
  baseURL : process.env.REACT_APP_BACKEND_URI,
});

instance.interceptors.request.use(function (config) {
  // Add required properties to the headers before the call made(sync/async)
  config.headers['Access-Token'] = localStorage.getItem('access_token');
  config.headers['uid'] =  localStorage.getItem('user_id');
  return config;
});


export default instance;                                                                                                           