import {React, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, TextField } from '@mui/material';
import { HeadingSmall } from "@hm-group/fabric-components/brand/hm";

import instance from '../../services/UserService';
import PageContainer from '../../components/PageContainer';

export default function Medias({ selectedShowroomID, submenu }) {
  const [medias, setMedias] = useState([]);

  const navigate = useNavigate();

  useEffect( () => {
    const getMedias = async() => {
      const response = await instance.get("/getMedias/"+selectedShowroomID);
      setMedias(response.data.map(media => ({ value: media.mediaId, label: media.mediaName})));
    };

    if(selectedShowroomID) {
      getMedias();
    }
  },[selectedShowroomID])

  return (
    <PageContainer title="Medias" submenu={submenu}>
      <HeadingSmall>Select media</HeadingSmall>
      <br />
      <Autocomplete
        disablePortal
        classes={{
          option: 'hm-select-options-text'
        }}
        id="medias-standard"
        options={medias}
        renderOption={(props, option) => (
          <li {...props} key={option.label}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className="hm-text-field hm-text-field--single"
            placeholder="Search for media ..."
          />
        )}
        onChange={(event, value) => navigate('/media/'+String(value.value))}
      />
    </PageContainer>
  );
}
