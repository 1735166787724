import { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const Authenticate = ({ children }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    useEffect(() => {
        async function authenticate() {
            if (!isAuthenticated && inProgress === InteractionStatus.None) {
                await instance.loginRedirect();
            }
        }

        authenticate();
    }, [isAuthenticated, inProgress, instance]);


    /*if (isAuthenticated ) {
        window.history.pushState('', "", "/loan/default/1");
    }*/

    return children;
};
export default Authenticate;
