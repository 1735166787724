import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";

import { Stack } from "@mui/material";
import { TablePagination } from '@material-ui/core';
import { BodyText, Modal, TextInput, HeadingSmall } from "@hm-group/fabric-components/brand/hm";

import instance from '../../../services/UserService';
import PageContainer from '../../../components/PageContainer';
import ShowroomList from './ShowroomList';

export default function Showrooms({ submenu }) {
  const [showrooms, setShowrooms] = useState({});
  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [successMessage, setSuccessMessage] = useState();

  const location = useLocation();

  // On component load
  useEffect( () => {
    if(location.state) {
      setSuccessMessage(location.state);
      window.history.replaceState({}, '/admin/showrooms');
    }
  },[])

  // Get showrooms whenever the props change
  useEffect(() => {
    const getShowrooms = () => {
      instance
      .get('/getAllShowrooms', {
        params: {
          'showroomName': filterData.showroom ? `contains(${filterData.showroom})` : null,
          'size': rowsPerPage,
          'page': page,
          'sort': 'showroomName,ASC',
        }
      })
      .then(response => {
        setShowrooms(response.data);
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getShowrooms();
  },[filterData, page, rowsPerPage, successMessage])

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <PageContainer title="Showrooms" submenu={submenu}>
      {successMessage && (
        <Modal
          isOpen={!!successMessage}
          heading="Success!"
          content={<BodyText dangerouslySetInnerHTML={{__html: `${successMessage}`}}/>}
          closeLabel="Close"
          size="medium"
          onClose={() => setSuccessMessage()}
        />
      )}

      <Stack direction="row" marginBottom={3} spacing={2} alignItems="baseline">
        <HeadingSmall>FILTER LIST BY:</HeadingSmall>
        <TextInput
          placeholder="Showroom name"
          name="filterShowroom"
          isMarginless
          onChange={e => {
            setFilterData({...filterData, showroom: e.target.value});
            setPage(0);
          }}
          value={filterData.showroom || ''}
        />
      </Stack>
      
      <ShowroomList showrooms={showrooms.content} />

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={showrooms?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PageContainer>
  );
}

