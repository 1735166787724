import React from 'react';

import { Stack } from "@mui/material";
import { FormLabel } from "@hm-group/fabric-components/brand/hm";

import hmLogo from '../../images/hmlogoBK1.jpg';
import Barcode from './Barcode';

const LabelPreviewWithPN = ({ sampleData, printRef, barcodeValue, showScreen }) => {
  return (
    <div>
      <FormLabel>Sample label preview</FormLabel>
      <Stack direction="column" justifyContent="space-between"  className="label" ref={printRef}>
        <Stack direction="row" justifyContent="space-between">
          <div><img src= {hmLogo} height='60px' alt='hmlogo'/></div>
          <div><Barcode value={barcodeValue} /></div>
        </Stack>

        <table className="label-data">
          <tbody>
            <tr className="label-data__row">
              <td className="label-data__title">Section:</td>
              <td className="label-data__info">{sampleData.sectionName}</td>
            </tr>
            <tr className="label-data__row">
              <td className="label-data__title">Department:</td>
              <td className="label-data__info">{sampleData.departmentName}</td>
            </tr>
            {showScreen === 'withProductNumber' && (
              <>
                <tr className="label-data__row">
                  <td className="label-data__title">Product No:</td>
                  <td className="label-data__info">{sampleData.productNumber}</td>
                </tr>
                <tr className="label-data__row">
                  <td className="label-data__title">Price:</td>
                  <td className="label-data__info">{`${sampleData.price} ${sampleData.currencyCode}`}</td>
                </tr>
                <tr className="label-data__row">
                  <td className="label-data__title">Sizes:</td>
                  <td className="label-data__info">{sampleData.sizes}</td>
                </tr>
                <tr className="label-data__row">
                  <td className="label-data__title">In Shop:</td>
                  <td className="label-data__info">{sampleData.inShopWeek}</td>
                </tr>
                <tr className="label-data__row">
                  <td className="label-data__title">Composition:</td>
                  <td className="label-data__info">{sampleData.composition}</td>
                </tr>
              </>
            )}
            {sampleData.information && (
              <tr className="label-data__row">
                <td colSpan="2" className="label-data__footer">{sampleData.information}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Stack>
    </div>
  );
}

export default LabelPreviewWithPN;